<template>
 <div class="lg:mx-4">
  <div class="lg:ml-4 px-2">
   <div class="md:pl-0 pr- pt-5">
    <slot name="selector"></slot>
   </div>
  </div>
  <div
   v-if="$route.name !== 'ExpertStatisticsGroups'"
   :class="[
    $route.name === 'ExpertStatisticsThirdPartyNumber' ? 'pt-6 pb-4' : 'pt-2',
    'lg:flex justify-between items-center text-center lg:ml-4 px-2 ',
   ]">
   <div class="lg:w-1/3 lg:-mt-10">
    <HoverDatePicker
     :periodSelector="periodSelector"
     @timeSelector="$emit('timeSelector', $event)"
     :elements="elements"
     :clearTimeSelector="clearTimeSelector"
     @selector="
      $emit('update:selector', $event),
       $emit('setSelector', $event),
       $store.dispatch('pbxPeriodSelected', $event)
     "
     @setPeriod="
      (...args) => {
       $emit('setPeriod', ...args);
      }
     " />
   </div>
   <div
    v-if="$route.name !== 'ExpertStatisticsThirdPartyNumber'"
    class="lg:flex lg:mt-0 mt-8">
    <div class="flex gap-2">
     <span class="hidden sm:block">
      <button
       type="button"
       :title="$t('Envoyer un rapport')"
       class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-100 focus:outline-none encom_ring_color"
       @click="
        this.$refs.popupShareReport.show({
         title: $t('pbxReport.shareTitle'),
         message: $t('pbxReport.shareMessage'),
         isSchedule: false,
        })
       ">
       <font-awesome-icon
        icon="share"
        class="h-5 w-5 text-gray-500"
        aria-hidden="true" />
      </button>
     </span>

     <span class="hidden sm:block">
      <button
       type="button"
       :title="$t('Créer un rapport')"
       class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-100 focus:outline-none encom_ring_color"
       @click="
        this.$refs.popupShareScheduleReport.show({
         title: $t('pbxReport.shareScheduleTitle'),
         message: $t('pbxReport.shareScheduleMessage'),
         isSchedule: true,
        })
       ">
       <font-awesome-icon
        :icon="['far', 'calendar-check']"
        class="h-5 w-5 text-gray-500"
        aria-hidden="true" />
      </button>
     </span>
    </div>
   </div>
  </div>
  <div class="max-w-full mx-auto py-4">
   <div
    class="lg:grid lg:grid-cols-4 lg:gap-8 sm:space-y-0 lg:space-y-0 lg:mr-2 lg:ml-6">
    <div
     class="col-span-4 space-y-2 pt-2 pb-12 bg-gray-50 shadow-md px-2 rounded-sm">
     <div class="">
      <slot class="" name="content"></slot>
      <!-- <div
       class="flex justify-center mt-10 text-xs max-w-sm text-center mx-auto">
       <span>
        <LightBulbIcon class="h-7 w-7" />
       </span>
       <p>
        Vous pouvez créer des groupe qui contiennent plusieurs files depuis le
        menu
        <router-link to="/expert-statistics/configuration/groups"
         ><span class="font-bold">Configuration</span></router-link
        >
        à gauche.
       </p>
      </div> -->
     </div>
    </div>
   </div>
   <TransitionRoot as="template" :show="reportList">
    <Dialog as="div" class="relative z-40" @close="reportList = false">
     <div class="fixed inset-0" />

     <div class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
       <div
        class="pointer-events-none fixed inset-y-0 right-0 top-12 flex max-w-full pl-10">
        <TransitionChild
         as="template"
         enter="transform transition ease-in-out duration-500 sm:duration-700"
         enter-from="translate-x-full"
         enter-to="translate-x-0"
         leave="transform transition ease-in-out duration-500 sm:duration-700"
         leave-from="translate-x-0"
         leave-to="translate-x-full">
         <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
          <div
           class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
           <div class="px-4 sm:px-6">
            <div class="flex items-start justify-between">
             <DialogTitle class="w-full text-lg font-medium text-gray-900">
              <div
               class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md mx-0">
               <h2 class="uppercase text-lg font-bold">
                Rapports 3CX
                {{
                 urlType === "queue"
                  ? "PAR FILE"
                  : urlType === "extension"
                  ? "PAR UTILISATEUR"
                  : "PAR numéro"
                }}
               </h2>
              </div>
              <!-- {{ $t("agentDetails", { agentNumber: agentNumber, agentName: agentName }) }} -->
             </DialogTitle>
             <div class="ml-3 flex h-7 items-center">
              <button
               type="button"
               class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
               @click="reportList = false">
               <span class="sr-only">Close panel</span>
               <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
             </div>
            </div>
           </div>
           <div class="relative mt-4 flex-1">
            <!-- Replace with your content -->
            <div class="absolute border-gray-200 bg-white w-full pb-2">
             <div class="flex flex-col mb-0">
              <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
               <div
                class="py-4 align-middle inline-block min-w-full sm:px-4 lg:px-4">
                <div
                 class="shadow overflow-hidden border-b border-gray-200 rounded-md">
                 <div
                  class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-300">
                   <thead class="bg-gray-50">
                    <tr>
                     <!-- Render the th element only if header.name is not 'id' -->
                     <template v-for="(header, index) in thead">
                      <th
                       v-if="header.name !== 'id'"
                       :key="index"
                       scope="col"
                       :class="{
                        'pl-4 pr-3 sm:pl-6': index === 1,
                        'px-3': index !== 1,
                       }"
                       class="py-3.5 text-left text-sm font-semibold text-gray-900 capitalize">
                       <span
                        v-if="
                         header.name.includes('_') &&
                         !header.name.includes('pattern') &&
                         !header.name === 'start_at'
                        ">
                        {{ $t(header.name.split("_")[1]) }}
                       </span>
                       <span v-else-if="header.name === 'repeat'">
                        Planifié
                       </span>
                       <span v-else-if="header.name === 'repeat_pattern'">
                        Chaque
                       </span>
                       <span v-else-if="header.name === 'report_type'">
                        {{ $t("type") }}
                       </span>
                       <span v-else>
                        {{ $t(header.name) }}
                       </span>
                      </th>
                     </template>
                     <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span class="sr-only">Edit</span>
                     </th>
                    </tr>
                   </thead>
                   <tbody class="divide-y divide-gray-200 bg-white truncate">
                    <slot
                     :row="row"
                     v-for="(row, rowIndex) in tbody"
                     :key="rowIndex">
                     <tr>
                      <!-- Render the td element only if the key is not 'id' -->
                      <template v-for="(value, key, index) in row">
                       <td
                        v-if="key !== 'id' && key !== 'start_at'"
                        :key="key"
                        :class="{
                         'pl-4 pr-3 sm:pl-6 font-medium text-gray-900':
                          index === 1,
                         'px-3 text-gray-500': index !== 1,
                        }"
                        class="whitespace-nowrap text-sm py-4 truncate max-w-sm">
                        <span class="truncate" v-if="value">
                         <span v-if="key === 'start' || key === 'end'">
                          <span v-if="row['repeat'] == 1 && key === 'end'">
                           <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            class="w-5 h-5">
                            <path
                             d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
                           </svg>
                          </span>
                          <span
                           v-else-if="row['repeat'] == 1 && key === 'start'">
                           {{
                            $d(new Date(row["start_at"]), "shortText", "fr")
                           }}
                          </span>
                          <span v-else>
                           {{ $d(new Date(value), "shortText", "fr") }}
                          </span>
                         </span>
                         <span v-else-if="key === 'repeat'">
                          <div v-if="value">
                           <span
                            class="px-2 py-1 bg-green-200 text-green-600 rounded-xl"
                            >Oui</span
                           >
                          </div>
                          <div v-else>
                           <span class="px-2 py-1 bg-blue-300 text-blue-600"
                            >Non</span
                           >
                          </div>
                         </span>
                         <span v-else-if="key === 'report_type'">
                          {{
                           value === "origins"
                            ? "Origine"
                            : value === "didReport"
                            ? "Rapport détaillé"
                            : value === "report"
                            ? "Rapport détaillé"
                            : value === "dashboard"
                            ? "Tableau de bord"
                            : value === "dashboard"
                            ? "Tableau de bord"
                            : $t(value)
                          }}
                         </span>
                         <span v-else>
                          {{ $t(value) }}
                         </span>
                        </span>
                        <span v-else>
                         <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-5 h-5">
                          <path
                           d="M6.75 9.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5z" />
                         </svg>
                        </span>
                       </td>
                      </template>
                      <td
                       class="px-6 py-6 whitespace-nowrap text-right text-sm font-medium flex justify-end items-center gap-2">
                       <a
                        :href="`https://presentation.cx-engine.net/${row.report_type}/${row.id}`"
                        target="_blank"
                        :class="[
                         'relative -ml-px inline-flex items-center bg-white px-2 py-1 text-sm font-semibold text-gray-700 hover:text-teal-600 ring-1 ring-inset ring-gray-300 hover:bg-teal-100 focus:z-10',
                        ]">
                        <svg
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20"
                         fill="currentColor"
                         class="w-5 h-5">
                         <path
                          d="M10 12.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                         <path
                          fill-rule="evenodd"
                          d="M.664 10.59a1.651 1.651 0 0 1 0-1.186A10.004 10.004 0 0 1 10 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0 1 10 17c-4.257 0-7.893-2.66-9.336-6.41ZM14 10a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
                          clip-rule="evenodd" />
                        </svg>
                       </a>
                       <button
                        @click.prevent="
                         deleteItem(
                          row.id,
                          $t('delete'),
                          $t('confirmationMessage'),
                          $t('confirm'),
                          $t('goBack'),
                          $t('successMsg')
                         )
                        "
                        type="button"
                        :class="[
                         'hover:bg-red-50 hover:text-red-500 hover:ring-inset hover:ring-1 hover:ring-red-500 text-gray-700 relative -ml-px inline-flex items-center bg-white px-2 py-1 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10',
                        ]">
                        <svg
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20"
                         fill="currentColor"
                         class="w-5 h-5">
                         <path
                          fill-rule="evenodd"
                          d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                          clip-rule="evenodd" />
                        </svg>
                       </button>
                      </td>
                     </tr>
                    </slot>
                   </tbody>
                  </table>
                  <PaginationComponent
                   :current_page="dataReports?.current_page"
                   :first_page_url="dataReports?.first_page_url"
                   :from="dataReports?.from"
                   :last_page="dataReports?.last_page"
                   :last_page_url="dataReports?.last_page_url"
                   :links="dataReports?.links"
                   :next_page_url="dataReports?.next_page_url"
                   :per_page="dataReports?.per_page"
                   :to="dataReports?.to"
                   :total="dataReports?.total"
                   @action="$emit('action', $event)"
                   @previousPage="previousPage()"
                   @nextPage="nextPage()" />
                 </div>
                </div>
               </div>
              </div>
             </div>
             <!-- <div v-else class="pt-20">
             <svg
              xmlns="http://www.w3.org/2000/svg"
              class="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
               stroke-linecap="round"
               stroke-linejoin="round"
               stroke-width="2"
               d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
             </svg>
             <h3 class="mt-2 text-lg font-medium text-gray-900">
              {{ $t("queueHasNoData") }}
             </h3>
            </div> -->
            </div>
            <!-- /End replace -->
           </div>
          </div>
         </DialogPanel>
        </TransitionChild>
       </div>
      </div>
     </div>
    </Dialog>
   </TransitionRoot>
  </div>
 </div>

 <PopupShareReport
  ref="popupShareReport"
  :elements="elements"
  :timeOption="this.buttonSelected"
  :elementOption="this.urlType"
  :parseDateRange="parseDateRange"
  :periodSelector="this.periodSelector"
  :selectHostName="this.hostName"
  selectHostContext="0"
  :startDate="startDate"
  :endDate="endDate"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event), $event ? getReportList() : ''"
  @reportList="reportList = true" />
 <PopupShareReport
  ref="popupShareScheduleReport"
  :elements="elements"
  :timeOption="this.buttonSelected"
  :elementOption="this.urlType"
  :parseDateRange="parseDateRange"
  :periodSelector="this.periodSelector"
  :selectHostName="this.hostName"
  selectHostContext="0"
  :startDate="startDate"
  :endDate="endDate"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event), $event ? getReportList() : ''"
  @reportList="reportList = true" />
 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
</template>

<script>
import AskConfirmationDialog from "../../components/AskConfirmationDialog.vue";
import PaginationComponent from "../../components/PaginationComponent.vue";
import axios from "axios";
import HoverDatePicker from "../../components/PBX/HoverDatePicker.vue";
import PopupShareReport from "../../components/PBX/PopupShareReport.vue";

import { LightBulbIcon } from "@heroicons/vue/outline";

import {
 Dialog,
 DialogPanel,
 DialogTitle,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";
import { mapGetters } from "vuex";
export default {
 name: "ExpertStatiticsTemplate",
 props: [
  "periodSelector",
  "elements",
  "clearTimeSelector",
  "startDate",
  "endDate",
  "urlType",
 ],
 components: {
  PopupShareReport,
  HoverDatePicker,
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  PaginationComponent,
  AskConfirmationDialog,
  LightBulbIcon,
 },
 data() {
  return {
   reportList: false,
   thead: [],
   tbody: [],
   dataReports: null,
  };
 },
 methods: {
  async deleteItem(
   id,
   title,
   confirmationMessage,
   confirmButton,
   goBack,
   successMsg
  ) {
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/reports/${id}`;
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    this.$emit("setLoading", true);
    axios
     .delete(url, {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
      },
     })
     .then(res => {
      if (res) {
       // this.sendMessage(successMsg);
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", successMsg);
       this.getReportList();
      }
     })
     .catch(error => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
   this.$emit("setLoading", false);
  },
  async getReportList() {
   this.$emit("setLoading", true);
   //  let reportType = this.getReportType();
   let reportType = null;
   let routeName = this.$route.name;
   let elementType = this.urlType ? (this.urlType === "queue" ? 4 : 0) : "*";
   if (routeName === "ExpertStatisticsDIDReport") {
    elementType = 4;
    reportType = this.getReportType();
   }
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/reports?page=${this.page}&filter[report_type]=${
    reportType ? reportType : ""
   }&filter[element_type]=${elementType}`;
   //&filter[report_type]=${reportType}&filter[element_type]=${elementType}
   try {
    const res = await axios.get(url, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
    });
    this.dataReports = res.data;
    console.log("getReportList ~ res.data:", res.data);

    if (res.data && res.data.data.length > 0) {
     const keyArr = Object.keys(res.data.data[0]);
     const excludedValues = [
      "name",
      "host_name",
      "created_at",
      "updated_at",
      "element_type",
      // "repeat_pattern",
      // "report_type",
      "dns",
      "repeat",
      "instant",
      "email",
      "email_sender",
     ];
     const excludedValuesHead = [
      "name",
      "host_name",
      "created_at",
      "updated_at",
      "element_type",
      "start_at",
      // "repeat_pattern",
      "repeat",
      // "report_type",
      "dns",
      "instant",
      "email",
      "email_sender",
     ];
     this.thead = keyArr
      .filter(name => !excludedValuesHead.includes(name))
      .map(name => ({ name, sort: "name", isSort: false }));

     this.tbody = res.data.data.map(obj => {
      const filteredObj = Object.fromEntries(
       Object.entries(obj).filter(([key]) => !excludedValues.includes(key))
      );
      return filteredObj;
     });
    }
   } catch (error) {
    this.errorHandling(error);
   } finally {
   }
   this.$emit("setLoading", false);
  },
  getReportType() {
   let routeName = this.$route.name;
   let type = null;

   switch (routeName) {
    case "ExpertStatistics":
     type = "origins";
     break;
    case "ExpertStatisticsOriginDetail":
     type = "originDetails";
     break;
    case "ExpertStatisticsAnsweredUnanswered":
     type = "answered";
     break;
    case "ExpertStatisticsAnsweredDaily":
     type = "answeredDaily";
     break;
    case "ExpertStatisticsQueueDetail":
     type = "queueDetails";
     break;
    case "ExpertStatisticsFullQueueReport":
     type = "report";
     break;
    case "ExpertStatisticsQueueComparator":
     type = "queuesComparison";
     break;
    case "ExpertStatisticsDIDReport":
     type = "didReport";
     break;
    case "Dashboard":
     type = "dashboard";
     break;
   }

   return type;
  },
 },
 mounted() {
  this.getReportList();
 },
 computed: {
  ...mapGetters(["hostName"]),
 },
};
</script>

<style></style>
